import React from 'react';

const Home4Apps = (data) => {
    const { shared } = data;

    return (
        <>
            <section className="sc-apps-media-section-area sc-md-pt-70">
                <div className="container">
                    <div className="row  align-items-center">
                        <div className="col-lg-8 sc-pb-70 sc-pt-70 sc-md-pb-70 sc-md-pt-70">
                            <div className="sc-media-content stretch">
                                <div className="sc-heading-area sc-mb-15">
                                    <h2 className="heading-title white-color">{shared.subscription.titleText}</h2>
                                </div>
                                <h4 className="description white-dark-color sc-mb-30">
                                    {shared.subscription.subTitleText}
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4 sc-pb-70 sc-pt-70 sc-md-pb-70 sc-md-pt-70">
                            <div className="sc-media-content stretch">
                                <div className="crupto-btn">
                                    <div className="text-right">
                                        <a className="sign-in-btn sc-mr-15" href={shared.subscription.formLink}><span>{shared.subscription.buttonText}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home4Apps;
