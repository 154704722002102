import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import FaqQuestion from './FaqQuestion';
import Footer from '../../Shared/Footer';
import Home4Apps from '../../Shared/Home4Apps';
import Helmet from 'react-helmet'

const Faq = (data) => {
    return (
        <>
        <Helmet>
                <title>{data.faq.pageTitle}</title>
                {
                    data.faq.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn' 
                {...data}
            />
            <BreadCums pageTitle={data.faq.title} />
            <FaqQuestion {...data} />
            <Home4Apps {...data} />
            <Footer {...data} />
        </>
    );
};

export default Faq;