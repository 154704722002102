import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const Home5Feature = (data) => {
    const { home } = data;
    return (
        <>
            <section>
                <div className="sc-crupto-style-two sc-pt-80 sc-md-pt-20 sc-md-mb-120 sc-pb-100 sc-md-pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <Fade left>
                                    <div className="sc-feature-image">
                                        <img src={require(`../../../../${home.ideas.image}`)} alt="Ideas" />
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6">
                                <Fade right>
                                    <div className="sc-crupto-content-two sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h2 className="heading-title sc-mb-25">
                                                {home.ideas.titleText}
                                            </h2>
                                            <div className="description sc-mb-25">
                                                {home.ideas.description}
                                            </div>
                                        </div>
                                        <div className="crupto_check sc-mb-40">
                                            <ul>
                                                {
                                                    home.ideas.promise.map((item, index) => {
                                                        return (<li key={index}>
                                                            <i className="ri-checkbox-circle-fill"></i> {item.text}
                                                        </li>)
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="crupto-btn">
                                            <Link className="sc-yellow-transparent sc-mr-15" to="/faq#"><span>FAQ</span></Link>

                                            <Link className="sc-yellow-btn" to="/contact#"><span>Contact Us</span></Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
};

export default Home5Feature;