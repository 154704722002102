import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const Home3Counter = (data) => {
    const { home } = data;
    return (
        <>
            <section className="sc-counter-area style-two sc-pt-60 sc-pb-65">
                <div className="container position-relative">
                    <div className="row align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-lg-7" data-aos="slide-up" data-aos-duration="800" data-aos-delay="400" >
                                <div className="sc-md-mb-25 text-left">
                                    <h2 className="count-title purple-color"> {home.ideas.titleText}</h2>
                                    <h5 className="count-title white-color"> {home.ideas.description}</h5>
                                </div>
                            </div>
                        </Fade>
                        <Fade right delay={500}>
                            <div className="col-lg-5" data-aos="slide-up" data-aos-duration="800" data-aos-delay="500">
                                <div className="text-center">
                                    <div className="crupto-btn">
                                        <Link className="primary-btn sc-mr-15" to="/faq#"><span>FAQ</span></Link>

                                        <Link className="submit-button" to="/contact#"><span>Contact Us</span></Link>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home3Counter;