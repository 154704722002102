import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home4Apps = (data) => {
    const { shared } = data;

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required')
    });

    const handleSubscribe = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        try {
            const response = await fetch(process.env.REACT_APP_SUBSCRIPTION_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            toast.success('Subscribed successfully!');
            resetForm();
        } catch (error) {
            console.error('Error subscribing:', error);
            toast.error('Error subscribing. Please try again later.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <section className="sc-apps-media-section-area sc-md-pt-70">
                <div className="container">
                    <div className="row align-items-left">
                        <div className="col-lg-12 sc-pb-70 sc-pt-70 sc-md-pb-70 sc-md-pt-70">
                            <div className="sc-media-content stretch">
                                <div className="sc-heading-area sc-mb-15">
                                    <h2 className="heading-title white-color">{shared.subscription.titleText}</h2>
                                </div>
                                <h4 className="description white-dark-color sc-mb-30">
                                    {shared.subscription.subTitleText}
                                </h4>
                                <div className="sc-media-form">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubscribe}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form>
                                                <Field
                                                    type="email"
                                                    id="emailOne"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    className="col-lg-12"
                                                    required
                                                />
                                                <ErrorMessage name="email" component="div" className="error" />
                                                <button className="sc-submit" type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Subscribing...' : shared.subscription.buttonText}
                                                </button>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home4Apps;
