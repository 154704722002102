import React from 'react';
import { Fade } from 'react-reveal';

const Home3Feature2 = (data) => {
    const { home } = data;
    return (
        <>
            <section className="sc-feature-section-area4 darker-bg sc-pt-140 sc-sm-pt-150">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image shake-y">
                                    <img  className="feature-image"  src={require(`../../../../${home.heroSection.image}`)} alt={home.heroSection.label} />
                                    <div className="feature-circle-shape"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color text-uppercase">{home.heroSection.label}</span>
                                        <h2 className="heading-title sc-mb-25">{home.heroSection.title}</h2>
                                        <div className="description sc-mb-25">{home.heroSection.description}</div>
                                    </div>
                                    <div className="feature_check sc-mb-40">
                                    <ul>
                                                {
                                                    home.heroSection.highlights.map((item, index) => {
                                                        return (<li key={index}>
                                                            <i className="ri-checkbox-circle-fill"></i> {item.text}
                                                        </li>)
                                                    })
                                                }
                                            </ul>
                                    </div>
                                    <div className="banner-btn-area">
                                        <a className="third-btn sc-mr-15" href={home.heroSection.appStoreLink} target="_blank" rel="noreferrer">
                                            <span> <i className="ri-apple-fill"></i> {home.heroSection.appStoreButtonText}</span>
                                        </a>
                                        <a className="sc-yellow-transparent" href={home.heroSection.playStoreLink} target="_blank" rel="noreferrer">
                                            <span> <i className="ri-google-play-fill"></i> {home.heroSection.playStoreButtonText}</span>
                                        </a>
                               
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home3Feature2;