import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BlogDetailsData = (props) => {
    const { terms } = props
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <h2 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                    {terms.title}
                                </h2>
                                <div className="blog-list-menu list_menu sc-pt-20 sc-mb-15">
                                    <ul className="blog-list">
                                        <li><i className="ri-edit-fill"></i> Last Updated: {terms.dateUpdated}</li>
                                    </ul>
                                </div>
                                <div className="blog-item sc-mt-25">
                                    <div className="blog-image sc-mb-30">
                                        <Link smooth to="#"><img src={require(`../../../../${terms.bigPicture}`)} alt={terms.title} /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>{terms.dateUpdated.split(' ')[0]}</li>
                                                <li>{terms.dateUpdated.split(' ')[1]}</li>
                                                <li>{terms.dateUpdated.split(' ')[2]}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <br />
                                        <h4>Introduction</h4>
                                        <p className="description sc-mb-25">
                                            This app is operated by <span style={{fontStyle: 'bold'}}>{terms.company.alias}</span>. The terms “we”, “us”, and “our” refer to {terms.company.fullName} of {terms.company.address}. The use of our app is subject to the following terms and conditions of use, as amended from time to time (the “Terms”). The Terms are to be read together by you with any terms, conditions, or disclaimers provided in the pages of our app. Please review the Terms carefully. The Terms apply to all users of our app, including without limitation, users who are browsers, customers, merchants, vendors, and/or contributors of content. If you access and use this app, you accept and agree to be bound by and comply with the Terms and our <a href={terms.privacyPolicyUrl}>Privacy Policy.</a>. If you do not agree to the Terms or our <a href={terms.privacyPolicyUrl}>Privacy Policy.</a>, you are not authorized to access our app, use any of our app's services or features, or make any transactions through our app.
                                        </p>

                                        <br />
                                        <h4>Use of our App</h4>
                                        <p className="description sc-mb-25">
                                            You agree to use our app for legitimate purposes and not for any illegal or unauthorized purpose, including without limitation, in violation of any intellectual property or privacy law. By agreeing to the Terms, you represent and warrant that you are at least 16 years of age and are legally capable of entering into a binding contract.
                                        </p>

                                        <br />
                                        <h4>General Conditions</h4>
                                        <p className="description sc-mb-25">
                                            We reserve the right to refuse service to anyone, at any time, for any reason. We reserve the right to make any modifications to the app, including terminating, changing, suspending, or discontinuing any aspect of the app at any time, without notice. We may impose additional rules or limits on the use of our app. You agree to review the Terms regularly, and your continued access or use of our app will mean that you agree to any changes.
                                        </p>

                                        <br />
                                        <h4>Products or Services</h4>
                                        <p className="description sc-mb-25">
                                            All purchases through our app are subject to product availability. We may, in our sole discretion, limit or cancel the quantities offered on our app or limit the sales of our products or services to any person, household, geographic region, or jurisdiction.
                                            Prices for our products are subject to change, without notice. Unless otherwise indicated, prices displayed on our app are quoted in Canadian dollars.
                                            We reserve the right, in our sole discretion, to refuse orders, including without limitation, orders that appear to be placed by distributors or resellers. If we believe that you have made a false or fraudulent order, we will be entitled to cancel the order and inform the relevant authorities.
                                            We do not guarantee the accuracy of the colour or design of the products on our app. We have made efforts to ensure the colour and design of our products are displayed as accurately as possible on our app.
                                        </p>

                                        <br />
                                        <h4>Links to Third-Party Apps</h4>
                                        <p className="description sc-mb-25">
                                            Links from or to apps outside our app are meant for convenience only. We do not review, endorse, approve, or control, and are not responsible for any apps linked from or to our app, the content of those apps, the third parties named therein, or their products and services. Linking to any other app is at your sole risk, and we will not be responsible or liable for any damages in connection with linking. Links to downloadable software sites are for convenience only, and we are not responsible or liable for any difficulties or consequences associated with downloading the software. Use of any downloaded software is governed by the terms of the license agreement, if any, which accompanies or is provided with the software.
                                        </p>

                                        <br />
                                        <h4>Intellectual Property</h4>
                                        <p className="description sc-mb-25">
                                            The content, features, and functionality of the app, including but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, are the intellectual property of {terms.company.fullName} and are protected by intellectual property laws, including copyright and trademark laws. You agree to abide by all copyright and other proprietary notices contained in such content and not to remove or modify any trademark or copyright information.
                                        </p>

                                        <br />
                                        <h4>Use Comments, Feedback, and Other Submissions</h4>
                                        <p className="description sc-mb-25">
                                            You acknowledge that you are responsible for the information, profiles, opinions, messages, comments, and any other content (collectively, the “Content”) that you post, distribute, or share on or through our app or services available in connection with our app. You further acknowledge that you have full responsibility for the Content, including but not limited to, with respect to its legality and its trademark, copyright, and other intellectual property ownership.
                                            You agree that any Content submitted by you in response to a request by us for a specific submission may be edited, adapted, modified, recreated, published, or distributed by us. You further agree that we are under no obligation to maintain any Content in confidence, to pay compensation for any Content, or to respond to any Content.
                                            You agree that you will not post, distribute, or share any Content on our app that is protected by copyright, trademark, patent, or any other proprietary right without the express consent of the owner of such proprietary right. You further agree that your Content will not be unlawful, abusive, or obscene nor will it contain any malware or computer virus that could affect our app’s operations. You will be solely liable for any Content that you make and its accuracy. We have no responsibility and assume no liability for any Content posted by you or any third-party.
                                            We reserve the right to terminate your ability to post on our app and to remove and/or delete any Content that we deem objectionable. You consent to such removal and/or deletion and waive any claim against us for the removal and/or deletion of your Content.
                                        </p>

                                        <br />
                                        <h4>Your Personal Information</h4>
                                        <p className="description sc-mb-25">
                                            Please see our <a href={terms.privacyPolicyUrl}>Privacy Policy.</a> to learn about how we collect, use, and share your personal information.
                                        </p>

                                        <br />
                                        <h4>Errors and Omissions</h4>
                                        <p className="description sc-mb-25">
                                            Please note that our app may contain typographical errors or inaccuracies and may not be complete or current. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time, without prior notice (including after an order has been submitted). Such errors, inaccuracies, or omissions may relate to product description, pricing, promotion, and availability, and we reserve the right to cancel or refuse any order placed based on incorrect pricing or availability information, to the extent permitted by applicable law.
                                            We do not undertake to update, modify, or clarify information on our app, except as required by law.
                                        </p>

                                        <br />
                                        <h4>Disclaimer and Limitation of Liability</h4>
                                        <p className="description sc-mb-25">
                                            You assume all responsibility and risk with respect to your use of our app, which is provided “as is” without warranties, representations, or conditions of any kind, either express or implied, with regard to information accessed from or via our app, including without limitation, all content and materials, and functions and services provided on our app, all of which are provided without warranty of any kind, including but not limited to warranties concerning the availability, accuracy, completeness, or usefulness of content or information, uninterrupted access, and any warranties of title, non-infringement, merchantability, or fitness for a particular purpose. We do not warrant that our app or its functioning or the content and material of the services made available thereby will be timely, secure, uninterrupted, or error-free, that defects will be corrected, or that our app or the servers that make our app available are free of viruses or other harmful components.
                                            The use of our app is at your sole risk, and you assume full responsibility for any costs associated with your use of our app. We will not be liable for any damages of any kind related to the use of our app.
                                            In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers, or employees be liable to you for any direct, indirect, special, incidental, consequential, exemplary, or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort (including negligence), strict liability, or otherwise, arising from your use of, or the inability to use, or the performance of, our app or the content or material or functionality through our app, even if we are advised of the possibility of such damages.
                                            Certain jurisdictions do not allow limitation of liability or the exclusion or limitation of certain damages. In such jurisdictions, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and our liability will be limited to the maximum extent permitted by law.
                                        </p>

                                        <br />
                                        <h4>Indemnification</h4>
                                        <p className="description sc-mb-25">
                                            You agree to defend and indemnify us, and hold us and our affiliates harmless, and our and their respective directors, officers, agents, contractors, and employees against any losses, liabilities, claims, expenses (including legal fees) in any way arising from, related to or in connection with your use of our app, your violation of the Terms, or the posting or transmission of any materials on or through the app by you, including but not limited to, any third party claim that any information or materials provided by you infringe upon any third party proprietary rights.
                                        </p>

                                        <br />
                                        <h4>Entire Agreement</h4>
                                        <p className="description sc-mb-25">
                                            The Terms and any documents expressly referred to in them represent the entire agreement between you and us in relation to the subject matter of the Terms and supersede any prior agreement, understanding, or arrangement between you and us, whether oral or in writing. Both you and we acknowledge that, in entering into these Terms, neither you nor we have relied on any representation, undertaking, or promise given by the other or implied from anything said or written between you and us prior to such Terms, except as expressly stated in the Terms.
                                        </p>

                                        <br />
                                        <h4>Waiver</h4>
                                        <p className="description sc-mb-25">
                                            Our failure to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision. A waiver by us of any default will not constitute a waiver of any subsequent default. No waiver by us is effective unless it is communicated to you in writing.
                                        </p>

                                        <br />
                                        <h4>Headings</h4>
                                        <p className="description sc-mb-25">
                                            Any headings and titles herein are for convenience only.
                                        </p>

                                        <br />
                                        <h4>Severability</h4>
                                        <p className="description sc-mb-25">
                                            If any of the provisions of the Terms are determined by any competent authority to be invalid, unlawful, or unenforceable, such provision will, to that extent, be severed from the remaining Terms, which will continue to be valid and enforceable to the fullest extent permitted by law.
                                        </p>

                                        <br />
                                        <h4>Governing Law</h4>
                                        <p className="description sc-mb-25">
                                            Any disputes arising out of or relating to the Terms, the <a href={terms.privacyPolicyUrl}>Privacy Policy.</a>, use of our app, or our products or services offered on our app will be resolved in accordance with the laws of {terms.company.jurisdiction} without regard to its conflict of law rules. Any disputes, actions, or proceedings relating to the Terms or your access to or use of our app must be brought before the courts of {terms.company.jurisdiction}, and you irrevocably consent to the exclusive jurisdiction and venue of such courts.
                                        </p>

                                        <br />
                                        <h4>Questions or Concerns</h4>
                                        <p className="description sc-mb-25">
                                            Please send all questions, comments, and feedback to us at <a href={`mailto:${terms.company.contactEmail}`}>{terms.company.contactEmail}</a>.
                                        </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetailsData;