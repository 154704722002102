import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BlogDetailsData = (props) => {
    const { privacy } = props
    return (
        <>
            <section>
                <div className="sc-blog-details sc-pt-140 sc-md-pt-80 sc-pb-20 sc-md-pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <h2 className="blog-title sc-pt-15 sc-mb-15 fw-bold">
                                    {privacy.company.alias}   {privacy.title}
                                </h2>
                                <div className="blog-list-menu list_menu sc-pt-20 sc-mb-15">
                                    <ul className="blog-list">
                                        <li><i className="ri-edit-fill"></i> Last Updated: {privacy.dateUpdated}</li>
                                    </ul>
                                </div>
                                <div className="blog-item sc-mt-25">
                                    <div className="blog-image sc-mb-30">
                                        <Link smooth to="#"><img src={require(`../../../../${privacy.bigPicture}`)} alt={privacy.title} /></Link>
                                    </div>
                                    <div className="blog-content p-0">
                                        <div className="blog_date">
                                            <ul>
                                                <li>{privacy.dateUpdated.split(' ')[0]}</li>
                                                <li>{privacy.dateUpdated.split(' ')[1]}</li>
                                                <li>{privacy.dateUpdated.split(' ')[2]}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-content-area sc-md-mb-50">
                                    <div className="details-content">
                                        <p className="description sc-mb-25">
                                            <br /><br />Privacy and responsible business are of utmost importance to us. At {privacy.company.fullName}, we consider privacy and data protection to be integral to all our business operations. We ensure the protection and security of personal data in accordance with applicable laws and regulations. This notification outlines the principles and procedures we adhere to when collecting and processing personal data.
                                            In this privacy notice, we provide details on how we handle the personal information of our customers, partners, and other contacts.
                                            For further details on cookies and the information collected through their use, please refer to our <a href={privacy.cookiePolicyUrl}>Cookie Policy.</a> page.
                                        </p>

                                        <h3>DATA CONTROLLER</h3>
                                        <p className="description sc-mb-25">
                                            {privacy.company.fullName} (Registration number {privacy.company.regNumber}, {privacy.company.address})
                                        </p>
                                        <h3 >CONTACT US IN PRIVACY MATTERS</h3>
                                        <p className="description sc-mb-25">
                                            With regrads to the above-mentioned company, the data controller, you can always be in touch through <a href={`mailto:${privacy.company.contactEmail}`}>{privacy.company.contactEmail}</a>.
                                        </p>
                                        <h3 >OVERVIEW OF OUR DATA PROTECTION PROTOCOLS</h3>
                                        <p className="description sc-mb-25">
                                            {privacy.company.alias} processes personal data in accordance with applicable legislation, including the Data Protection Act 2018, UK Public General Acts and this privacy notice.
                                            <br /><br />We consider the requirements outlined in data protection laws in all of our business operations, and we anticipate and demand the same from our subcontractors and other business associates.
                                            <br /><br />Our employees/consultants undergo orientation and training sessions to familiarize themselves with the privacy requirements and guidelines, ensuring they are equipped to perform their duties effectively.
                                        </p>
                                        <h3 >WHAT PERSONAL DATA WE PROCESS AND FOR WHAT PURPOSE?</h3>
                                        <p className="description sc-mb-25">
                                            The personal data we handle is exclusively used for specific purposes outlined below. These purposes include managing customer and partner relationships, marketing, sales, and fulfilling our legal responsibilities.
                                            <br /><br /></p>
                                        <h4>{privacy.company.alias} App Users</h4>
                                        <p className="description sc-mb-25">
                                            If you are downloaded and are using the app (either on google play store or Apple app store) we collect certain information, including:
                                        </p>
                                        <ul className="sc-mb-25" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                            <li>First name and surname</li>
                                            <li>Role and Position information</li>
                                            <li>Occupation</li>
                                            <li>Age</li>
                                            </ul>
                                        <p className="description sc-mb-25">
                                            The information we collect is used for personalization and user experience purposes only within the app. We do not transmit any information collected from within the app through the internet, neither do we store them in any virtual memory outside of the app.
                                        </p>
           
                                        <h4>Web Form and Cookies</h4>
                                        <p className="description sc-mb-25">
                                            When you reach out to us, whether it be to inquire about our services via the online form, or subscribe to our newsletter, we may handle personal data that is pertinent and usual for the intended purposes, provided that we have received this information from you:
                                        </p>
                                        <ul className="sc-mb-25" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                            <li>First name and surname</li>
                                            <li>Email address</li>
                                            <li>Telephone number</li>
                                            <li>Organization name and contact details</li>
                                            <li>Date of birth</li>
                                            <li>Country</li>
                                            <li>Role and contact details</li>
                                            <li>Other information you choose to provide us (e.g. web address of LinkedIn profile)</li>
                                        </ul>

                                        <p className="description sc-mb-25">
                                            The information we collect is used to assist in processing your inquiries or requests and to stay connected with you. Some of this data may also be utilized to personalize your app experience and could be stored solely in the phone's memory.
                                            <br /><br />The personal data collected consists primarily of the information that you choose to disclose to us. Additionally, the data may encompass information about your website activity via cookies.
                                            <br /><br />For further details regarding the cookies utilized on our website, please refer to our a <a href={privacy.cookiePolicyUrl}>Cookie Policy.</a>
                   
                                        </p>

                                        <h3 >WHAT ARE THE GROUNDS FOR PROCESSING PERSONAL DATA?</h3>
                                        <p className="description sc-mb-25">
                                            Our processing of personal data for the purposes outlined is primarily grounded on {privacy.company.alias}'s legitimate interest. This interest is primarily focused on executing a contract between {privacy.company.alias} and the company you are affiliated with, maintaining customer relationships, or other pertinent connections. Legitimate interest is also applicable when data processing is carried out for marketing and customer acquisition, managing and enhancing customer interactions, as well as other legitimate business activities like service development or preventive measures and investigations into misconduct.
                                            <br /><br />Furthermore, in certain instances, the processing may rely on your consent provided to us (for example, when you subscribe to our newsletter or register for our event in our system) or an obligation mandated by law or other legal considerations.
                                            <br /><br />Version 1: The disclosure of personal information as outlined in this privacy notice is contingent upon the mutual agreement between you or the company you are affiliated with. In order to enter into an agreement or provide services, it is necessary for you or the company you represent to disclose specific personal information. Neglecting to provide this information may result in {privacy.company.alias}'s inability to fulfill contractual obligations or meet other requirements. Additionally, the processing of personal data may be mandatory in order to facilitate requested communications or enable participation in our events.
                                        </p>

                                        <h3 >FOR HOW LONG DO WE PROCESS PERSONAL DATA?</h3>
                                        <p className="description sc-mb-25">
                                            We process personal data for only as long as required or entitled by applicable legislation. The length of storing the data, therefore, depends on the type of the data and the reason it is used for. {privacy.company.alias} stores personal data at least as long as it is necessary to fulfill the purpose of processing, for example for filling contractual requirements or for administering the contractual relationship between {privacy.company.alias} and customer.
                                            <br /><br />The length of storing the data is based on the following criteria:
                                            <ul className="description sc-mb-25">
                                                <li>Personal information is being stored as long as the legitimate interest of {privacy.company.alias} is reasonably applicable. It is defined, for example, based on the communications between {privacy.company.alias} and the one registered. The length of storing personal data for customers and partners is defined based on the agreement between the one registered or the company they represent and {privacy.company.alias}.</li>
                                                <li>If processing data is based on consent, personal information will be deleted when the one registered cancels the consent they have given.</li>
                                                <li>The length of storing the data may also be based on legislation. For example, bookkeeping legislation requires bookkeeping material to be stored for six years, and, for example, shareholder information is being stored as long as listed company legislation requires.</li>
                                            </ul>
                                        </p>

                                        <p className="description sc-mb-25">
                                            We regularly assess the need for the data stored and delete any information that is no longer required for the purposes outlined in this privacy notice.
                                        </p>
                                        <h3 >YOUR RIGHTS</h3>
                                        <p className="description sc-mb-25">
                                            {privacy.company.alias} acts diligently to ensure that you are able to exercise your rights regarding the processing of your personal data. Please note that your rights depend on the legal grounds of handling information and using your rights require verifying your identity. Within the limits of applicable legislation, you have a right to:
                                        </p>
                                        <ul className="sc-mb-25" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                            <li>Gain access to your personal information. You have the right to request {privacy.company.alias} to verify if we are processing your personal data, and to receive a copy of your personal data that may be processed by us, by submitting such a request to the email address provided in this privacy notice;</li>
                                            <li>Correction and/or deletion of data. You can ask us to rectify any inaccurate personal data or to delete your personal data. These rights can be exercised by submitting a request to the email address mentioned in this privacy notice;</li>
                                            <li>Limit the processing of your personal data. In specific circumstances, you have the option to request us to limit the processing of your personal data by submitting a request to the email address mentioned in this privacy notice;</li>
                                            <li>Raise objections to the processing of your personal data. If the processing is based on {privacy.company.alias}'s legitimate interest (e.g. using your personal data for marketing purposes), you have the right to object to certain processing by submitting a request to the email address mentioned in this privacy notice;</li>
                                            <li>Data portability. You can request us to provide your data in a structured, commonly used, and machine-readable format so that you can transfer your data to another controller by submitting a request to the email address mentioned in this privacy notice. This right applies to personal data in electronic format, processed based on your consent or the performance of a contract;</li>
                                            <li>Revoke your consent. If the processing is based on your consent, you have the right to withdraw that consent at any time by submitting a request to the email address mentioned in this privacy notice.</li>
                                            <li>Please be aware of the key components of the agreement between joint controllers. To exercise your rights, you can submit a request to the address provided in this privacy notice and, </li>
                                            <li>if you have concerns about how we handle your personal data in accordance with the GDPR, you have the right to file a complaint with the supervisory authority in the member state where you reside or work, or where an alleged violation of the GDPR has taken place. In the United Kingdom, the Data Protection Ombudsman serves as the relevant supervisory authority (<a href={'https://www.legislation.gov.uk/ukpga/2018/12/enacted'}>https://www.legislation.gov.uk/ukpga/2018/12/enacted</a>).</li>

                                        </ul>



                                        <h3 >PROTECTION OF PERSONAL DATA</h3>
                                        <p className="description sc-mb-25">
                                            The protection of personal data and information security at {privacy.company.alias} is implemented in accordance with industry best practices. Our aim is to safeguard personal data from unauthorized access, processing, destruction, loss, and corruption. We continuously enhance our practices to ensure the utmost protection of data. Our information security and risk management are based on a governance system and a policy that aligns with ISO/IEC 27002:2013 standards.
                                            <br /><br />The responsibility for maintaining information security and addressing any deviations lies with our dedicated information security group, led by its leader.
                                            <br /><br />We treat personal data as confidential information and all our users are bound by confidentiality obligations and adhere to our guidelines on data protection and information security. Access to confidential information is managed through a centralized or system-specific user directory, with access and user rights granted only to authorized individuals after proper identification and verification. We regularly assess and review access and user rights to ensure their appropriateness.
                                            <br /><br />When we engage third parties for personal data processing, we establish agreements that comply with data protection legislation to ensure compliance with our privacy policies and applicable laws and regulations.

                                        </p>
                                        <h3 >TRANSFER OF DATA</h3>
                                        <p className="description sc-mb-25">
                                            We utilize third-party data systems and services to process personal data, meaning that these third parties handle the personal data on our behalf. Examples of such third parties include AWS, Google, Zoho, Microsoft, and Hubspot. Some of our data processors are located outside of the EU or EEA or European Region. In these instances, we ensure through contracts that the transfer of data complies with the relevant data protection laws. The data transfers associated with these contracts are based on standard clauses approved by the EU Commission or other appropriate safeguards.

                                            <br /><br />If the processing of personal data requires the transfer of data between {privacy.company.alias} and its subsidiary, we may share personal data within the {privacy.company.alias}. In such cases, we guarantee that the processing of personal data adheres to the applicable data protection laws and this privacy notice. If you require additional information regarding cross-border transfers of personal data or the protective measures employed, please send a request to the address provided at the beginning of this privacy notice.

                                            <br /><br />We may exchange a limited amount of contact information of our customers with our partners and vice versa, if necessary to provide services, communications, or collaboration.

                                            <br /><br />We may disclose personal information within the boundaries of the effective legislation and/or when obligated by law, such as for legal reasons, to prepare for legal proceedings, or to defend against legal claims. Additionally, we may share personal data with collection agencies for the purpose of debt collection, as well as other service providers as required to fulfill their tasks.

                                            <br /><br />In the event that we sell our business or a portion of it, or undergo other corporate restructurings, we may disclose personal information to potential buyers or their advisors.
                                        </p>
                                        <h3 >UPDATES TO THIS PRIVACY NOTICE</h3>
                                        <p className="description sc-mb-25">
                                            Changes in the processing of personal data or applicable laws prompt us to update this privacy notice. Furthermore, updates may occur as we enhance our website, services, or business activities. You can find the latest version of the privacy notice on our website.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetailsData;