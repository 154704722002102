import React from 'react';
import Header from '../../Shared/Header';
import Works from './Works';
import Home3Counter from './Home3Counter';
import Home3Feature2 from './Home3Feature2';
import Footer from '../../Shared/Footer';
import ClientTestimonial from '../../Shared/ClientTestimonial';
import Home4Apps from '../../Shared/Home4Apps';
import Helmet from 'react-helmet';


const HomeFive = (data) => {
    return (
        <>
            <Helmet>
                <title>{data.home.pageTitle}</title>
                {
                    data.home.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <Header
                parentMenu='home' 
                headerNormalLogo={require(`../../../../${data.shared.homeLogo}`)}
                headerStickyLogo={require(`../../../../${data.shared.homeStickyLogo}`)}
                headerClass='sc-header-section sc-header-section5'
                {...data}
            />
            <Home3Feature2 {...data} />
            <Works {...data} />
            <Home3Counter {...data} />
            <ClientTestimonial {...data} />
            <Home4Apps {...data} />
            <Footer
                footerStyle="sc-footer-style-two"
                {...data}
            />
        </>
    );
};

export default HomeFive;